import classNames from 'classnames/bind';
import React from 'react';
import InView, { useInView } from 'react-intersection-observer';
import * as styles from './About.module.scss';

const c = classNames.bind(styles);

function About({ title, text, cta = {}, setIndex }) {
	// const link = cta.url && new URL(cta.url).pathname;
	const link = 'https://www.sqli.com/fr-fr/insights-news/communiques-de-presse/sqli-acquisition-Levana-experience-client-Salesforce';

	const { inView, ref } = useInView({
		triggerOnce: false,
		initialInView: true,
	});

	return (
		<section ref={ref} className={c('wrapper', { inView })}>
			<h2 className={c('title')}>{title}</h2>

			<p className={c('text')}>
				{text.split(' ').map((word, i) => (
					<span key={i} className={c('word')}>
						<span
							className={c('inner')}
							style={{ animationDelay: i * 50 + 300 + 'ms' }}
						>
							{word}&nbsp;
						</span>
					</span>
				))}
			</p>

			<a className={c('cta')} href={link}>
				{cta.title}
			</a>

			<InView
				rootMargin="-50% 0%"
				className={c('observer')}
				onChange={(inView) => inView && setIndex(-1)}
			/>
		</section>
	);
}

export default About;

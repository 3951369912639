export function mapLinear(nbr, in_min, in_max, out_min, out_max) {
	return ((nbr - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
}

export function lerp(v0, v1, t) {
	return v0 * (1 - t) + v1 * t;
}

export function getSinAndCos(angleEnDegres) {
	const angleEnRadians = (angleEnDegres * Math.PI) / 180;
	const sin = Math.sin(angleEnRadians);
	const cos = Math.cos(angleEnRadians);
	return { sin, cos };
}

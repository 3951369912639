import classNames from 'classnames/bind';
import React from 'react';
import BigButton from '../../../common/BigButton/BigButton';
import * as styles from './EndCta.module.scss';

const c = classNames.bind(styles);

function EndCta({ text, url, hidden }) {
	const link = new URL(url).pathname;

	return (
		<section className={c('wrapper', { hidden })}>
			<p className={c('text')}>
				{text.split(' ').map((word, i) => (
					<span key={i} className={c('word')}>
						<span className={c('inner')} style={{ '--index': i }}>
							{word}&nbsp;
						</span>
					</span>
				))}
			</p>
			<BigButton to={link} className={c('button')} />
		</section>
	);
}

export default EndCta;

import classNames from 'classnames/bind';
import { graphql, useStaticQuery } from "gatsby";
import React, { useContext } from 'react';
import { LanguageContext } from '../../../utils/LanguageContext';
import * as styles from './Discover.module.scss';

const c = classNames.bind(styles);

function Discover({ className, translateDiscover }) {
	const language = useContext(LanguageContext);
	const query  = useStaticQuery(graphql`
		query {
			wp {
				sp {
					strings {
						header_discover_label {
							en
							fr
						}
					}
				}
			}
		}
	`)
	const strings = query.wp.sp.strings.header_discover_label;
	return (
		<button 
			className={c('containerDiscover', className)}
			onClick={translateDiscover} 
			aria-label={
				language.languageCode === "FR" 
					? "Aller à la section suivante" 
					: "Navigate to next section"
			}
		>
			<span>{language.languageCode === "FR" ? strings.fr : strings.en}</span>
			<div className={c('line')}></div>
		</button>
	);
}

export default Discover;

import classNames from 'classnames/bind';
import React, { Component, createRef } from 'react';
import { lerp, mapLinear } from '../../../../utils/maths';
import BigButton from '../../../common/BigButton/BigButton';
import Dash from '../../../common/Dash/Dash';
import * as styles from './StickySlide.module.scss';

const c = classNames.bind(styles);

const Y_MAX = 20; // pourcentage max du translateY sur l'image
const SMOOTH_FACTOR = 0.1; // 0 -> 1, définit l'intensité du smooth du parallaxe (1 = instantané, 0 = infinite smooth)

class StickySlide extends Component {
	scrollAmount = 0;
	y = { prev: 0, next: 0 };
	mustTick = false; // permet de mettre en pause le raf si l'anim est finie
	rafId = undefined;
	imgRef = createRef();

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll, { passive: true });
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll = () => {
		this.scrollAmount = window.pageYOffset;

		if (this.rafId === undefined) {
			this.mustTick = true;
			this.rafId = window.requestAnimationFrame(this.raf);
		}
	};

	raf = () => {
		if (!this.imgRef.current || !this.mustTick) return;

		// calcul du progress : 1 > 0 > -1
		const { startScrollAmount: start, endScrollAmount: end } = this.props;
		this.y.next = mapLinear(this.scrollAmount, start, end, 1, -1);

		// lerp
		this.y.prev = lerp(this.y.prev, this.y.next, SMOOTH_FACTOR);

		// parallaxe
		const val = Y_MAX * this.y.prev;
		this.imgRef.current.style.setProperty('--transform', `translateY(${val}%)`);

		// the loop : https://i.redd.it/i9mkkxjp8c931.jpg
		if (Math.abs(this.y.prev - this.y.next) < 0.01) this.stopLoop();
		else this.loop();
	};

	loop() {
		this.rafId = window.requestAnimationFrame(this.raf);
	}

	stopLoop() {
		this.mustTick = false;
		cancelAnimationFrame(this.rafId);
		this.rafId = undefined;
	}

	render() {
		const { title, preTitle, link, img, top, bottom } = this.props;

		return (
			<section className={c('wrapper', { top, bottom })}>
				<p className={c('preTitle')}>{preTitle}</p>

				<h2 className={c('title')}>
					{title.split(' ').map((word, i) => (
						<span key={i} className={c('word')}>
							<span className={c('inner')} style={{ '--index': i }}>
								{word}&nbsp;
							</span>
						</span>
					))}
				</h2>

				<Dash className={c('dash')} />

				<figure className={c('figure')} ref={this.imgRef}>
					<img
						src={img.src}
						alt={img.alt}
						loading="lazy"
						className={c('img')}
					/>
				</figure>

				<BigButton to={link} className={c('cta')} />
			</section>
		);
	}
}

export default StickySlide;

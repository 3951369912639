// extracted by mini-css-extract-plugin
export var cta = "About-module--cta--Hj33f";
export var enterB2t = "About-module--enterB2t--278s6";
export var inView = "About-module--inView--ciYC+";
export var inner = "About-module--inner--GeKdZ";
export var leaveB2t = "About-module--leaveB2t--9SDVB";
export var observer = "About-module--observer--N8FrB";
export var text = "About-module--text--jnBfl";
export var title = "About-module--title--kSnWZ";
export var word = "About-module--word--y8K4O";
export var wrapper = "About-module--wrapper--p7Jhq";
// extracted by mini-css-extract-plugin
export var bottom = "StickySlide-module--bottom--nDUKT";
export var cta = "StickySlide-module--cta--c5QDZ";
export var dash = "StickySlide-module--dash--GKjNh";
export var figure = "StickySlide-module--figure--Z34m1";
export var img = "StickySlide-module--img--cMagH";
export var inner = "StickySlide-module--inner--6mqoc";
export var preTitle = "StickySlide-module--preTitle--Eacur";
export var title = "StickySlide-module--title--Mhvhq";
export var top = "StickySlide-module--top--UT2Ue";
export var word = "StickySlide-module--word--xmTRX";
export var wrapper = "StickySlide-module--wrapper--8eB2l";
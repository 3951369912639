import classNames from 'classnames/bind';
import React, { Component } from 'react';
import { Curtains, Plane } from 'react-curtains';
import { hexToRgb } from '../../../utils/colors';
import { getSinAndCos, lerp } from '../../../utils/maths';
import { noiseShader } from '../../../utils/noiseShader';
import * as styles from './Background.module.scss';

const c = classNames.bind(styles);

const COLOR_TRANSITION_SPEED = 0.03;
const SCALE_SPEED = 0.2;
const MAX_SCALE = 2;
const MAX_ROTATION = 20; // degrees
const WAVE_AMP = 0.7;
const WAVE_FREQ = 0.7;
const WAVE_SPEED = 0.5;

const basicVs = /* glsl */ `
	precision highp float;

	attribute vec3 aVertexPosition;
	attribute vec2 aTextureCoord;

	uniform mat4 uTextureMatrix0;
	uniform float uScale;
	uniform vec2 uRotation;
	uniform float uTime;

	varying vec3 vVertexPosition;
	varying vec2 vTextureCoord;

	void main() {
		// Rotate
		vec2 rotated = vec2(
			aVertexPosition.x * uRotation.y + aVertexPosition.y * uRotation.x,
			aVertexPosition.y * uRotation.y - aVertexPosition.x * uRotation.x
		);
		
		// Zoom		
		vec2 scaled = uScale * rotated.xy;

		gl_Position = vec4(scaled, 0., 1.0);

		// varyings
		vVertexPosition = aVertexPosition;
		vTextureCoord = (uTextureMatrix0 * vec4(aTextureCoord, 0.0, 1.0)).xy;
	}
`;

const basicFs = /* glsl */ `
	precision highp float;

	varying vec3 vVertexPosition;
	varying vec2 vTextureCoord;

	uniform sampler2D uSampler0;
	uniform vec3 uColor;
	uniform float uTime;

	${noiseShader}

	vec3 blendOverlay(vec3 base, vec3 blend) {
		return mix(1.0 - 2.0 * (1.0 - base) * (1.0 - blend), 2.0 * base * blend, step(base, vec3(0.5)));
	}

	void main() {
		// Wave
		vec3 pos = vec3(vTextureCoord, 0.);
		float noiseFreq = ${WAVE_FREQ};
		float noiseAmp = ${WAVE_AMP}; 
		vec3 noisePos = vec3(pos.x * noiseFreq + uTime * ${WAVE_SPEED / 100
	}, pos.y, pos.z);
		pos.z += snoise(noisePos) * noiseAmp;
		float wave = pos.z * 0.2;

		// Pick texel from texture
		float intensity = 1. - vVertexPosition.x * vVertexPosition.y; // on empêche les coins bas gauche et haut droit d'être trop déformés
		// float r = texture2D(uSampler0, vTextureCoord + wave * intensity).r;
		// float g = texture2D(uSampler0, vTextureCoord + wave * 2. * intensity).g;
		// float b = texture2D(uSampler0, vTextureCoord + wave * 3. * intensity).b;
		// vec3 texel = vec3(r, g ,b);
		vec3 texel = texture2D(uSampler0, vTextureCoord + wave * intensity).rgb;

		// Color blend
		vec4 color = vec4(blendOverlay(texel, uColor), 1.);

		// Noise blend		
		float strength = 3.;
		vec2 uv = vTextureCoord.xy;
		float x = (uv.x + 4.0 ) * (uv.y + 4.0 ) * (uTime * 10.0);
		vec4 grain = 1. - vec4(mod((mod(x, 13.0) + 1.0) * (mod(x, 123.0) + 1.0), 0.01)-0.005) * strength;

		gl_FragColor = color * grain - .05; // le .05 c'est pour baisser la luminosité pour que le texte soit lisible
	}
`;

const basicUniforms = {
	time: { name: 'uTime', type: '1f', value: 0 },
	color: { name: 'uColor', type: '3fv', value: [0, 0, 0] },
	scale: { name: 'uScale', type: '1f', value: 1 },
	rotation: { name: 'uRotation', type: '2fv', value: [0, 1] },
};

class Background extends Component {
	r = { prev: 0, next: 0 };
	g = { prev: 0, next: 0 };
	b = { prev: 0, next: 0 };
	scale = { prev: 1, next: 1 };
	rotation = { prev: 0, next: 0 };
	pageH = 1000;

	componentDidMount() {
		this.changeColor();
		this.handleResize();
		window.addEventListener('resize', this.handleResize);
		window.addEventListener('scroll', this.handleScroll, { passive: true });
	}

	componentDidUpdate() {
		this.changeColor();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleResize = () => {
		this.pageH = document.body.clientHeight - window.innerHeight;
		this.handleScroll();
	};

	handleScroll = () => {
		const percentage = window.pageYOffset / this.pageH;
		this.scale.next = 1 + (MAX_SCALE - 1) * percentage;
		this.rotation.next = MAX_ROTATION * percentage;
	};

	changeColor() {
		const { r, g, b } = hexToRgb(this.props.color);
		this.r.next = r / 255;
		this.g.next = g / 255;
		this.b.next = b / 255;
	}

	onRender = (plane) => {
		// Lerps
		this.r.prev = lerp(this.r.prev, this.r.next, COLOR_TRANSITION_SPEED);
		this.g.prev = lerp(this.g.prev, this.g.next, COLOR_TRANSITION_SPEED);
		this.b.prev = lerp(this.b.prev, this.b.next, COLOR_TRANSITION_SPEED);
		this.scale.prev = lerp(this.scale.prev, this.scale.next, SCALE_SPEED);
		this.rotation.prev = lerp(
			this.rotation.prev,
			this.rotation.next,
			SCALE_SPEED,
		);

		// Uniforms
		plane.uniforms.time.value++;
		plane.uniforms.color.value = [this.r.prev, this.g.prev, this.b.prev];
		plane.uniforms.scale.value = this.scale.prev;
		const { cos, sin } = getSinAndCos(this.rotation.prev);
		plane.uniforms.rotation.value = [sin, cos];
	};

	render() {
		return (
			<div className={c('wrapper')}>
				<Curtains watchScroll={false} pixelRatio={1}>
					<Plane
						className={c('plane')}
						vertexShader={basicVs}
						fragmentShader={basicFs}
						onRender={this.onRender}
						uniforms={basicUniforms}
					>
						<img alt="" className={c('img')} src="/images/home-bg@3838.jpg" />
					</Plane>
				</Curtains>
			</div>
		);
	}
}

export default Background;

import classNames from 'classnames/bind';
import React, { useEffect, useRef, useState } from 'react';
import InView from 'react-intersection-observer';
import EndCta from './EndCta/EndCta';
import StickySlide from './StickySlide/StickySlide';
import * as styles from './StickySlides.module.scss';

const c = classNames.bind(styles);

const SLIDE_HEIGHT = 1; // fois 100vh

function StickySlides({ works = [], cta, setIndex, index }) {
	const ref = useRef();
	const [offset, setOffset] = useState(0);
	const [slideHeight, setSlideHeight] = useState(1000);

	useEffect(() => {
		handleResize();
		function handleResize() {
			const { pageYOffset, innerHeight } = window;
			const { top } = ref.current.getBoundingClientRect();
			setOffset(top + pageYOffset + innerHeight / 2);
			setSlideHeight(innerHeight * SLIDE_HEIGHT);
		}

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<div
			ref={ref}
			className={c('wrapper')}
			style={{ '--slide-height': SLIDE_HEIGHT * 100 + 'vh' }}
		>
			<div className={c('slidesWrapper')}>
				{works.map((work, i) => (
					<StickySlide
						key={i}
						{...work}
						top={index > i}
						bottom={index < i}
						startScrollAmount={offset + slideHeight * i}
						endScrollAmount={offset + slideHeight * (i + 1)}
					/>
				))}

				<EndCta {...cta} hidden={index !== works.length} />

				{/* Observers sur mobile */}
				{[...new Array(works.length + 1)].map((_, i) => (
					<InView
						key={i}
						rootMargin="-50% 0%"
						style={{ gridRow: i + 1 }}
						className={c('observer', 'mobileOnly')}
						onChange={(inView) => inView && setIndex(i)}
					/>
				))}
			</div>

			{/* Observers sur desktop (on en rajoute un pour gérer l'offset qui permet de ne pas scroll sur le premier slide) */}
			{[...new Array(works.length + 2)].map((_, i) => (
				<InView
					key={i}
					rootMargin="-50% 0%"
					className={c('observer', 'desktopOnly')}
					onChange={(inView) => inView && setIndex(i - 1)}
				/>
			))}
		</div>
	);
}

export default StickySlides;

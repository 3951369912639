import classnames from 'classnames/bind';
import { graphql } from 'gatsby';
import React, { useState } from 'react';
import About from '../../components/Homepage/About/About';
import Background from '../../components/Homepage/Background/Background';
import Intro from '../../components/Homepage/Intro/Intro';
import StickySlides from '../../components/Homepage/StickySlides/StickySlides';
import Seo from '../../components/Seo/Seo';
import * as styles from './Homepage.module.scss';

const c = classnames.bind(styles);

export const query = graphql`
	query($id: String!, $lang: WpLanguageCodeEnum) {
		page: wpPage(id: { eq: $id }, language: { code: { eq: $lang } }) {
			seo {
				title
				metaDesc
				opengraphTitle
				opengraphDescription
				opengraphSiteName
				opengraphImage {
					uri
				}
				twitterTitle
				twitterDescription
				twitterImage {
					uri
				}
			}
			translations {
				uri
				language {
					locale
				}
			}
			acf: acfPageHomepage {
				intro: header {
					title
				}
				about {
					title
					text: description
					cta {
						title
						url
					}
				}
				strates {
					strate1 {
						id
					}
					strate2 {
						id
					}
					strate3 {
						id
					}
				}
				cta {
					text: title
					url
				}
			}
		}
		works: allWpWork(filter: { language: { code: { eq: $lang } } }) {
			nodes {
				uri
				databaseId
				acf: acfSingleWork {
					color_home
					title_home
					headline_home
					image_home {
						src: url
						alt: title
					}
				}
			}
		}
	}
`;

export default function Homepage({ data }) {
	const [index, setIndex] = useState(-1);

	const { seo, translations, acf } = data.page;
	const { intro, about, strates, cta } = acf;

	// récup des works
	const { strate1, strate2, strate3 } = strates;
	const worksIds = [strate1.id, strate2.id, strate3.id];
	const works = data.works.nodes
		.filter((work) => worksIds.includes(work.databaseId))
		.map((work) => ({
			title: work.acf.headline_home,
			preTitle: work.acf.title_home,
			link: work.uri,
			img: work.acf.image_home,
			color: work.acf.color_home,
		}));

	// Colors
	const baseColor = '#133db0';
	const color = works[index]?.color || baseColor;

	return (
		<main className={c('wrapper')}>
			<Seo {...{ seo, translations }} />
			<Intro {...intro} setIndex={setIndex} />
			<About {...about} setIndex={setIndex} />
			<StickySlides {...{ works, cta, setIndex, index }} />
			<Background color={color} />
		</main>
	);
}

import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { use100vh } from 'react-div-100vh';
import InView from 'react-intersection-observer';
import Discover from '../../Intro/Discover/Discover';
import * as styles from './Intro.module.scss';

const c = classNames.bind(styles);

function Intro({ title, setIndex }) {
	const height = use100vh();

	const [scrolled, setScrolled] = useState(false);

	useEffect(() => {
		function handleScroll() {
			setScrolled(window.pageYOffset > 0);
		}

		window.addEventListener('scroll', handleScroll, { passive: true });

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<section style={{ height }} className={c('wrapper')}>
			<h1 className={c('title')}>
				{title.split(' ').map((word, i) => (
					<span key={i} className={c('word')}>
						<span
							className={c('inner')}
							style={{ animationDelay: i * 250 + 600 + 'ms' }}
						>
							{word}&nbsp;
						</span>
					</span>
				))}
			</h1>

			<Discover className={c('discover', { hidden: scrolled })} />

			<InView
				rootMargin="-50% 0%"
				className={c('observer')}
				onChange={(inView) => inView && setIndex(-1)}
			/>
		</section>
	);
}

export default Intro;
